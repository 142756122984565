import logo from './logo.svg';
import loader from './loader.svg';
import top from './top.svg';
import blog0 from './blog0.jpg';
import blog1 from './blog1.jpg';
import blog2 from './blog2.jpg';
import blog3 from './blog3.jpg';
import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import {
  Switch,
  Route,
  useHistory,
  // useLocation
} from "react-router-dom";


function Passos(props) {
  let fill = props.passo*100/5;
  return (
    <div>
      <p className="passos">Passo {props.passo} de 5</p>
      <div className="filler"><div className="fill" style={{width: fill + '%'}}></div></div>
    </div>
  );
}

function Pergunta(props) {
  let sub = <p className="sub">&nbsp;{props.perg.sub||null}&nbsp;</p>;
  return (
    <div>
      <p className="pergunta">{props.perg.pergunta}</p>
      {sub}
    </div>
  );
}

function BlogList(props) {
  let posts = [
    {img: blog0, txt: "Planejar e alcançar a independência financeira pode levar menos tempo do que parece", link: "https://www.metlife.com.br/blog/planejamento-financeiro/planejar_e_alcancar_a_independencia_financeira_pode_levar_menos_tempo_do_que_parece/"},
    {img: blog1, txt: "5 perguntas que você precisa fazer antes de se aposentar", link: "https://www.metlife.com.br/blog/produto/5-perguntas-antes-de-se-aposentar/"},
    {img: blog2, txt: "13º salário: veja 7 maneiras de usar a renda extra com inteligência", link: "https://www.metlife.com.br/blog/planejamento-financeiro/13-salario-veja-7-maneiras-de-usar-a-renda-extra-com-inteligencia/"},
    {img: blog3, txt: "5 pontos para você refletir sobre sua relação com o dinheiro", link: "https://www.metlife.com.br/blog/planejamento-financeiro/5-pontos-para-voce-refletir-sobre-sua-relacao-com-o-dinheiro/"}
  ];
  let divs = [];
  for(let post of posts){
    divs.push(
      <div>
        <img src={post.img} alt="blog"/>
        <p>{post.txt}</p>
        <button onClick={(e) => { window.open(post.link, "_blank"); }}>Leia esse post</button>
      </div>
    );
  }
  return (
    <div className="BlogList">
      {divs}
    </div>
  )
}

function useContainerWidth(myRef: React.RefObject<any>) {
  const [width, setWidth] = useState(0);


  useEffect(() => {
    const getWidth = () => ( (myRef && (myRef.current?myRef.current.offsetWidth:myRef.offsetWidth)) || 0 );

    const handleResize = () => {
      setWidth(getWidth());
    };
    if (myRef.current) {
      setWidth(getWidth());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef]);

  return width;
}
function App() {
  // let location = useLocation();
  let history = useHistory();
  useEffect(() => {
    getParams();
  }, []);
  let perguntas = [
    null,
    {dado: "renda", mask: "money", pergunta: "Qual é o valor da sua Renda Bruta Anual Tributável?\r\nOu seja, a soma do seu salário bruto anual.", sub: "(caso não tenham ocorrido variações ao longo do ano, o valor de seu salário mensal multiplicado por 12)"},
    {dado: "bonus", mask: "money", pergunta: "Qual é o valor de remuneração variável e/ou outras fontes\r\nde renda recebidas ao longo do ano fiscal atual?", sub: "Exemplos: bônus salarial, rendas provenientes do aluguel de imóveis ou outras fontes de renda sujeitas a tributação de Imposto de Renda.\r\nImportante esclarecer que PLR e 13º não podem ser considerados Remuneração Variável em função de serem rendimentos sujeitos a tributação exclusiva na fonte e consequentemente não estarem sujeitos ao ajuste na declaração anual de Imposto de Renda."},
    {dado: "desp", mask: "money", pergunta: "Qual o valor das despesas com PGBL?", sub: "(Contribuições + Aporte)"},
    {dado: "depend", mask: "number", pergunta: "Qual a quantidade de Dependentes?"},
    {dado: "deduc", mask: "money", pergunta: "Qual o valor de outras Deduções Fiscais*?", sub: "(exceção de PGBL)"},
  ]
  const mask = {
    money: {
      prefix: 'R$ ',
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: '.',
      allowDecimal: true,
      decimalSymbol: ',',
      decimalLimit: 3, // how many digits allowed after the decimal
      integerLimit: 11,
      allowNegative: false,
      allowLeadingZeroes: false,
    },
    number: {
      prefix: '',
      includeThousandsSeparator: false
    }
  };
  const [passo, setPasso] = useState(1);
  const [dados, setDados] = useState({
    renda:'',
    bonus:'',
    desp:'',
    depend:'',
    deduc:'',
  });
  const [pergunta, setPergunta] = useState(perguntas[1]);
  const [input, setInput] = useState("");
  const [show, setShow] = useState({Form: true, Result:false, Loading:false});
  const [params, setParams] = useState(null);
  const [result, setResult] = useState(null);
  const changeInput = (e) => {
    var v = e.target.value;
    if(pergunta.mask === "money"){
      v = v.replace(/\D/g,'');
      v = (''+(parseInt(v) / 100)).replace('.', ',');
      let idx = v.indexOf(',');
      if(isNaN(v) && v.length === 3 && idx === 1) v += '0';
      else if(!isNaN(v) && v > 0 && idx === -1) v += ',00';
      else if(idx === v.length - 4) v = v.substr(0,-1);
      else if(v === 0) v = '';
    }else{
      v = v = v.replace(/\D/g,'');
    }
    setInput(v);
  }
  const Avancar = (e) => {
    let d = dados;
    let v = input;
    if(pergunta.mask === "money" && typeof v === 'string'){
      v = v.replace(/\D/g,'');
      v = (parseInt(v) / 100) || '';
    }
    d[pergunta.dado] = v;
    setDados(d);
    if(passo < 5){
      let p = passo+1;
      setPasso(p);
      setPergunta(perguntas[p]);
      setInput((d[perguntas[p].dado]+'').replace(".", ","));
    }else{
      setShow({...show, Form: false, Loading: true, Result: false});
      if(params === null){
        getParams((ok)=>{
          if(ok){
            calcData();
          }else{
            alert('Erro ao buscar dados da tabela!');
            setShow({...show, Form: true, Loading: false, Result: false});
          }
        })
      }else{
        calcData();
      }
    }
  }
  const Voltar = (e) => {
    let d = dados;
    let v = input;
    if(pergunta.mask === "money" && typeof v === 'string'){
      v = v.replace(/\D/g,'');
      v = (parseInt(v) / 100) || '';
    }
    d[pergunta.dado] = v;
    setDados(d);
    let p = passo-1;
    setPasso(p);
    setPergunta(perguntas[p]);
    setInput((d[perguntas[p].dado]+'').replace(".", ","));
  }

  const getParams = (cb) => {
    fetch("/params.json")
      .then(res => res.json())
      .then(
        (result) => {
          setParams(result);
          if(typeof cb === 'function') cb(true);
        },
        (error) => {
          if(typeof cb === 'function') cb(false);
        }
      );
  }

  const calcINSS = () => {
    let v = dados.renda / 12;
    let inss = 0;
    let a = null;
    for(let i in params.INSS.tabela) {
      a = params.INSS.tabela[i];
      if(a.de < v && a.ate > v) break;
    }
    inss = a.aliquota;
    return Math.min(params.INSS.max, inss * dados.renda);
  }

  const calcIR = (v) => {
    let a = null;
    for(let i in params.IR) {
      a = params.IR[i];
      if(a.de < v && (a.ate > v || v === "")) break;
    }
    return { aliquota: a?a.aliquota:0, deducao: a?a.deducao:0 };
  }

  const calcData = () => {
    let inss = calcINSS();
    let atual = {
      renda: dados.renda||0,
      bonus: dados.bonus||0,
      inss: inss||0,
      desp: dados.desp||0,
      depend: dados.depend||0,
      outras: dados.deduc||0,
      deduc: ((dados.depend * params.ddep) + dados.deduc)||0,
    };
    atual.baseIR = Math.max(0, (atual.renda+atual.bonus) - atual.inss - atual.desp - atual.deduc );
    let air = calcIR(atual.baseIR);
    atual = {...atual,
      aliqIR: air.aliquota||0,
      parcDeduc: air.deducao||0,
    };
    atual.IRTot = (atual.baseIR * atual.aliqIR) - atual.parcDeduc;
    let max = {
      renda: atual.renda,
      bonus: atual.bonus,
      inss: atual.inss,
      desp: Math.max(atual.desp, 0.12*(atual.renda+atual.bonus)),
      depend: atual.depend,
      outras: atual.outras,
      deduc: atual.deduc,
    };
    max.baseIR = Math.max(0, (max.renda+max.bonus) - max.inss - max.desp - max.deduc );
    let mir = calcIR(max.baseIR);
    max = {...max,
      aliqIR: mir.aliquota,
      parcDeduc: mir.deducao,
    };
    max.IRTot = (max.baseIR * max.aliqIR) - max.parcDeduc;

    let apoio = Math.max(0, (dados.renda+dados.bonus) - inss - max.deduc );
    let apoioIR = calcIR(apoio);
    let apoioTot = (apoio * apoioIR.aliquota) - apoioIR.deducao;
    atual.incentivo = apoioTot - atual.IRTot;
    max.incentivo = apoioTot - max.IRTot;
    setResult({
      info: (atual.aliqIR === 0?"A sua Base para cálculo de IR encontra-se na alíquota de 0%, logo, as contribuições com o plano de previdência não são utilizadas para fins de dedução fiscal":(atual.desp>atual.renda?"Favor verificar os valores de contribuição PGBL.":(max.desp<=atual.desp?"As suas contribuições já atingiram o maior incentivo fiscal previsto na legislação vigente.":"Você ainda pode contribuir com "+((max.desp-atual.desp).toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}))+" para atingir o incentivo fiscal máximo!"))),
      atual: atual,
      max: max,
    });
    setShow({...show, Form: false, Loading: false, Result: true});
  }

  function Reset() {
    // setDados({renda:'',bonus:'',contrib:'',aporte:'',depend:'',deduc:''});
    setPasso(1);
    setPergunta(perguntas[1]);
    if(dados && dados.renda) setInput((dados.renda+'').replace(".", ","));
    setShow({...show, Form: true, Loading: false, Result: false});
    history.push('/simular');
  }

  const tbl1Ref = useRef();
  const tbl1w = useContainerWidth(tbl1Ref);
  const tbl2Ref = useRef();
  const tbl2w = useContainerWidth(tbl2Ref);
  const tbl3Ref = useRef();
  const tbl3w = useContainerWidth(tbl3Ref);

  const AppRef = useRef();

  const MakePDF = () => {
    let el = AppRef.current || AppRef;
    let pdfCanvas = document.getElementById('pdfCanvas');
    el.classList.add('pdf');
    html2canvas(el, {
      logging: true,
      useCORS: true,
      removeContainer: true,
      scale: 0.65,
      windowWidth: 1260,
      windowHeight: 900,
      width: 1260,
      height: 2500
    }).then(function(canvas) {
      let ctx = canvas.getContext("2d");
      let imageData = ctx.getImageData(0,0,canvas.width,canvas.height);
      let data = imageData.data;
      let crop = 0;
      for(var x = 0, len = data.length; x < len; x+=4) {
        let r = data[x], g = data[x+1], b = data[x+2];
        if(r !== 255 || g !== 255 || b !== 255){
          crop = Math.floor((x/4)/imageData.width) - 25;
          break;
        }
      }
      imageData = ctx.getImageData(0, crop, canvas.width, canvas.height - crop);
      pdfCanvas.width = imageData.width;
      pdfCanvas.height = imageData.height;
      ctx = pdfCanvas.getContext("2d");
      ctx.putImageData(imageData, 0, 0);
      let divImage = pdfCanvas.toDataURL("image/png", 1.0);
      let pdf = new jsPDF({orientation: 'p',unit: 'mm',format: 'A4'});
      pdf.addImage(divImage, 'PNG', 0, 0);
      pdf.save("Simulação"+(new Date().toLocaleDateString('pt-BR'))+".pdf");
      el.classList.remove('pdf');
      history.push('/fim');
    })
  }

  return (
    <div className="App" ref={AppRef}>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <Switch>
        <Route exact path="/">
          <section className="Home">
            <div className="top">
              <img src={top} alt="top"/>
              <div className="top-text">
                  <h3>
                    Mais contribuições,
                    <br/>
                    e mais incentivo.
                  </h3>
                  <p>
                    Ao contribuir para o seu Plano de Previdência PGBL, <br/> você pode receber um incentivo final na sua<br/>declaração completa do Imposto de Renda.
                  </p>
                  <p>
                    Faça a simulação com a MetLife.
                  </p>
                  <button onClick={Reset}>Comece sua simulação</button>
                </div>
              </div>
            <div class='video'><iframe src='https://www.youtube.com/embed/NeFA0EnTUG0' title="youtube" frameborder='0' allowfullscreen></iframe></div>
          </section>
          <section className="End">
            <h3>Confira nossas dicas sobre educação financeira no Blog MetLife Brasil</h3>
            <BlogList />
          </section>
        </Route>
        <Route path="/simular">
          <p className={show.Form?"title":show.Result?"title-result":"hidden"}></p>
          <section className={show.Form?"Form":"hidden"}>
            <Passos passo={passo}/>
            <div>
              <Pergunta perg={pergunta}/>
              <MaskedInput mask={createNumberMask(mask[pergunta.mask])} placeholder={pergunta.mask==="money"?'R$ 0,00':0} inputMode="numeric" value={input} onChange={changeInput}/>
              <p className="sub">Insira o valor no campo</p>
            </div>
            <div className="btns">
              {passo>1?<button className="btn-voltar" onClick={Voltar}>Voltar</button>:null}
              <button className={passo<5?"btn-avancar":"btn-simular"} onClick={Avancar}>{passo===5?'Simular':'Avançar'}</button>
            </div>
          </section>
          <section className={show.Loading?"Loading":"hidden"}>
            <img src={loader} className="App-loader" alt="loader" />
          </section>
          <section className={show.Result?"Result":"hidden"}>
            <div className="padw">
              <div className="hinfo">
                <div>Sua simulação</div>
                <div>Simulação realizada em: {new Date().toLocaleDateString('pt-BR')}</div>
              </div>
              <div className="Info">
                {result?result.info:''}
              </div>
              <div className="s13">
                <b>Aproveite seu 13°!</b> Procure o time de RH da sua empresa, e verifique oportunidades de realizar um aporte extra para desconto na Folha de Pagamento de dezembro. Ou, entre em contato com nossa Central de Atendimento para emissão de um boleto para o recolhimento da sua contribuição.
              </div>
            </div>
            <div className="detalhes">
              <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'top', justifyContent: 'strech', maxWidth: '100%'}}>
                  <div style={{flexGrow:1}} ref={tbl1Ref}>
                    <div className="TblHeader"><strong>Demonstrativo</strong> (Anual)</div>
                    <ul style={{background:'none', textAlign: 'left'}}>
                      <li>Renda Bruta Anual Tributável (sem PLR)</li>
                      <li>Bônus / Gratificação</li>
                      <li>INSS</li>
                      <li>Despesas com Plano de Previdência PGBL (Contribuições + Aportes)</li>
                      <li>Quantidade de Dependentes</li>
                      <li>Outras Deduções Fiscais* (exceção de PGBL)</li>
                      <li>Deduções Fiscais (exceção de PGBL)</li>
                      <li>&nbsp;</li>
                      <li>Base para Cálculo IR</li>
                      <li>Alíquota de IR</li>
                      <li>Parcela a Deduzir</li>
                      <li>Imposto de Renda Total</li>
                    </ul>
                  </div>
                  <div style={{margin: '0 15px'}} ref={tbl2Ref}>
                    <div className="TblHeader"><strong>Situação Atual</strong> (R$)</div>
                    <ul>
                      <li>{result?result.atual.renda.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.atual.bonus.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.atual.inss.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?parseFloat(result.atual.desp).toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.atual.depend:'0'}</li>
                      <li>{result?result.atual.outras.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.atual.deduc.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'0'}</li>
                      <li>&nbsp;</li>
                      <li>{result?result.atual.baseIR.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.atual.aliqIR.toLocaleString('pt-BR', {style: 'percent', maximumFractionDigits: 1}):'0'}</li>
                      <li>{result?result.atual.parcDeduc.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.atual.IRTot.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                    </ul>
                  </div>
                  <div ref={tbl3Ref}>
                    <div className="TblHeader"><strong>Contribuição máxima</strong> (R$)</div>
                    <ul>
                      <li>{result?result.max.renda.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.max.bonus.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.max.inss.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.max.desp.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.max.depend:'0'}</li>
                      <li>{result?result.max.outras.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.max.deduc.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'0'}</li>
                      <li>&nbsp;</li>
                      <li>{result?result.max.baseIR.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.max.aliqIR.toLocaleString('pt-BR', {style: 'percent', maximumFractionDigits: 1}):'0'}</li>
                      <li>{result?result.max.parcDeduc.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                      <li>{result?result.max.IRTot.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</li>
                    </ul>
                  </div>
                </div>
                <div className="Total">
                  <div style={{width: tbl1w + 'px', textAlign: 'left'}}>Incentivo Fiscal</div>
                  <div style={{width: tbl2w + 'px', textAlign: 'right', margin: '0 15px'}}>{result?result.atual.incentivo.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</div>
                  <div style={{width: tbl3w + 'px', textAlign: 'right'}}>{result?result.max.incentivo.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</div>
                </div>
              </div>
            </div>
            <div className="mobile">
              <div className="Total">
                <div className="TblHeader"><strong>Situação Atual</strong> (R$)</div>
                <div>{result?result.atual.incentivo.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</div>
                <div className="TblHeader"><strong>Contribuição máxima</strong> (R$0,00)</div>
                <div style={{color: '#007ABC'}}>{result?result.max.incentivo.toLocaleString('pt-BR', {style: 'currency', currency:'BRL'}):'R$ 0,00'}</div>
              </div>
            </div>
            <div className="pad">
              <div className="btns-sim">
                <button className="btn-reset" onClick={Reset}>Refazer Simulação</button>
                <button className="btn-pdf" onClick={MakePDF}></button>
              </div>
            </div>
          </section>
        </Route>
        <Route path="/fim">
          <section className="Sucesso"><p></p></section>
          <section className="End">
            <h3>Confira nossas dicas sobre educação financeira no Blog MetLife Brasil</h3>
            <BlogList />
          </section>
        </Route>
      </Switch>
      <div className="pad" style={{backgroundColor:(show.Form?'#FFF':'transparent')}}>
        <footer>
          * Consideram-se "Deduções Fiscais" o montante de deduções informadas pelo cliente, excluindo a contribuição anual para previdência complementar.<br/>
          ** 12% é o limite máximo permitido na legislação tributária para fins de dedução na declaração de ajuste, o que significa que o participante pode contribuir com valor superior, porém não poderá utilizar o excedente como dedução.
          <br/>
          IMPORTANTE: Todas as simulações aqui apresentadas tem a intenção de servir como ferramenta ilustrativa e não como aconselhamento financeiro.<br/>
          As premissas adotadas para cálculo são estimativas, e, de forma alguma, pretendem garantir qualquer performance pela MetLife. Os resultados apresentados no simulador e as hipóteses financeiras aqui apresentadas são meras estimativas feitas com base nas informações apresentadas pelo usuário. Em investimentos de longo prazo como é o caso de Plano de Previdência, deve-se atentar para as oscilações que podem ocorrer no cenário econômico
          e financeiro, pois elas interferem diretamente na performance das carteiras. Os valores aqui apresentados são estimativas e foram calculados considerando-se o pagamento das contribuições acima estipulados, bem como os índices financeiros/econômicos na data da simulação e a legislação
          atual, portanto qualquer mudança nas informações fornecidas e premissas estabelecidas, como por exemplo: valor de contribuição, período de acumulação, oscilações nos índices financeiros/econômicos, mudança de legislação, irão modificar diretamente os resultados apresentados.		Produtos de Previdência registrados na SUSEP e sob responsabilidade da Metropolitan Life Seguros e Previdência Privada S.A., código SUSEP 0635-1, CNPJ 02.102.498/0001-29.
          O registro deste plano na SUSEP não implica, por parte da Autarquia, incentivo ou recomendação a sua comercialização. As condições contratuais/regulamento deste produto encontram-se registradas na SUSEP de acordo com o número de processo constante
          da apólice/proposta e poderão ser consultadas no endereço eletrônico www.susep.gov.br. Para conhecer na íntegra as condições do produto,
          consulte as Condições Gerais, Condições Particulares ou o Manual do Segurado.
          <br/>
          Simulação realizada em {new Date().toLocaleDateString('pt-BR')}.
        </footer>
      </div>
      <canvas id="pdfCanvas" style={{display:'none'}}></canvas>
    </div>
  );
}
export default App;
